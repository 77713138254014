import { ethers } from 'ethers';
import { wrapProvider } from 'ui-sdk';

export const getAAProvider = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  // Request account access if needed
  await window.ethereum.request({ method: 'eth_requestAccounts' });

  const signer = provider.getSigner();

  // Fetch environment variables for configuration
  const entryPointAddress = process.env.REACT_APP_ENTRY_POINT_ADDRESS;
  const bundlerUrl = process.env.REACT_APP_BUNDLER_URL;
  const factoryAddress = process.env.REACT_APP_ACCOUNT_FACTORY_ADDRESS;
  const factoryManagerAddress = process.env.REACT_APP_FACTORY_MANAGER_ADDRESS;

  // Validate entry point address
  if (!entryPointAddress || !ethers.utils.isAddress(entryPointAddress)) {
    throw new Error(
      `Invalid or missing entry point address. Ensure REACT_APP_ENTRY_POINT_ADDRESS is set and is a valid Ethereum address.`
    );
  }

  // Validate bundler URL
  if (!bundlerUrl) {
    throw new Error(
      `Invalid or missing bundler URL. Ensure REACT_APP_BUNDLER_URL is set in the environment variables.`
    );
  }

  // Validate factory addresses
  const isFactoryAddressValid = factoryAddress && ethers.utils.isAddress(factoryAddress);
  const isFactoryManagerAddressValid = factoryManagerAddress && ethers.utils.isAddress(factoryManagerAddress);

  if (!isFactoryAddressValid && !isFactoryManagerAddressValid) {
    throw new Error(
      `Either factoryAddress (REACT_APP_ACCOUNT_FACTORY_ADDRESS) or factoryManagerAddress (REACT_APP_FACTORY_MANAGER_ADDRESS) must be set and be a valid Ethereum address.`
    );
  }

  // Define configuration after validation
  const config = {
    chainId: await provider.getNetwork().then((net) => net.chainId),
    entryPointAddress,
    bundlerUrl,
    factoryAddress: isFactoryAddressValid ? factoryAddress : undefined, // Use if valid
    factoryManagerAddress: isFactoryManagerAddressValid ? factoryManagerAddress : undefined // Use if valid
  };

  // Wrap the provider with Account Abstraction
  const aaProvider = await wrapProvider(provider, config, signer);

  // Validate the wrapped provider
  if (!aaProvider.getSigner()) {
    throw new Error('Failed to initialize the Account Abstraction provider.');
  }

  return aaProvider;
};
