import { ethers } from 'ethers';
import { wrapProvider } from 'ui-sdk';
import { initializePasskeySigner } from './webAuthnService';

// Function to handle authentication (signing with an existing passkey)
export const getAAPasskeyProvider = async () => {
  // Initialize the passkey signer
  const passkeySigner = await initializePasskeySigner();

  // Configuration for the provider
  const entryPointAddress = process.env.REACT_APP_ENTRY_POINT_ADDRESS;
  const bundlerUrl = process.env.REACT_APP_BUNDLER_URL;
  const factoryAddress = process.env.REACT_APP_PASSKEY_ACCOUNT_FACTORY_ADDRESS;
  const rpcUrl = process.env.REACT_APP_RPC_URL;

  // Validate entry point address
  if (!entryPointAddress || !ethers.utils.isAddress(entryPointAddress)) {
    throw new Error('Invalid or missing entry point address.');
  }

  // Validate bundler URL
  if (!bundlerUrl) {
    throw new Error('Invalid or missing bundler URL.');
  }

  const config = {
    chainId: 11690,
    entryPointAddress,
    bundlerUrl,
    factoryAddress,
  };

  // Create a new JSON-RPC provider (based on bundler)
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  // Wrap the provider with Account Abstraction
  const aaProvider = await wrapProvider(provider, config, passkeySigner);
  console.log('aaProvider', aaProvider);

  return aaProvider;
};
