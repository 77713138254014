import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { getAAProvider } from './aaProvider';
import { getAAPasskeyProvider } from './aaPasskeyProvider';
import { registerPasskey } from './webAuthnService';

function App() {
  const [authenticationMethod, setAuthenticationMethod] = useState(null);
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [provider, setProvider] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [isMetaMaskAvailable, setIsMetaMaskAvailable] = useState(true);
  const [passkeyName, setPasskeyName] = useState('');

  // Function to fetch balance from the provider
  const updateWalletBalance = async (provider, address) => {
    try {
      const balance = await provider.getBalance(address);
      setBalance(parseFloat(ethers.utils.formatEther(balance)).toFixed(2));
    } catch (error) {
      console.error('Error fetching balance:', error);
      setStatus(`Error: ${error.message}`);
    }
  };

  // Initialize MetaMask wallet
  const initializeWallet = useCallback(async () => {
    try {
      const provider = await getAAProvider();
      const signer = provider.getSigner();
      const address = await signer.getAddress();

      setProvider(provider);
      setWalletAddress(address);
      setAuthenticated(true);
      updateWalletBalance(provider, address);
    } catch (error) {
      console.error('Error initializing MetaMask wallet:', error);
      setStatus(`Error: ${error.message}`);
    }
  }, []);


  useEffect(() => {
    if (window.ethereum && authenticationMethod === 'metamask') {
      setIsMetaMaskAvailable(true);

      // Initialize wallet on component mount
      initializeWallet();

      // Listen for account changes
      window.ethereum.on('accountsChanged', initializeWallet);

      // Clean up the event listener on component unmount
      return () => {
        window.ethereum.removeListener('accountsChanged', initializeWallet);
      };
    } else {
      setIsMetaMaskAvailable(false);
      setStatus(''); // Clear any duplicate status message when MetaMask is not available
    }
  }, [initializeWallet, setIsMetaMaskAvailable, authenticationMethod]);

  // Update balance every 5 second
  useEffect(() => {
    const interval = setInterval(() => {
      if (provider && walletAddress) {
        updateWalletBalance(provider, walletAddress);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [provider, walletAddress]);

  // Register a new passkey and initialize provider
  const registerNewPasskey = useCallback(async () => {
    if (!passkeyName) {
      setStatus('Please enter a name for your passkey.');
      return;
    }
    try {
      await registerPasskey(passkeyName);
      setStatus('New passkey created successfully.');
      const provider = await getAAPasskeyProvider();
      const address = await provider.getSigner().getAddress();
      setProvider(provider);
      setWalletAddress(address);
      await updateWalletBalance(provider, address);
      setAuthenticated(true);
    } catch (error) {
      console.error('Error registering new passkey:', error);
      setStatus(`Error: ${error.message}`);
    }
  }, [passkeyName]);

  // Initialize the provider with existing passkey
  const initializePasskeyProvider = useCallback(async () => {
    try {
      const provider = await getAAPasskeyProvider();
      const signer = provider.getSigner();
      const address = await signer.getAddress();

      setProvider(provider);
      setWalletAddress(address);
      setAuthenticated(true);
      updateWalletBalance(provider, address);
    } catch (error) {
      console.error('Error initializing Passkey wallet:', error);
      setStatus(`Error: ${error.message}`);
    }
  }, []);

  // Handle method selection between MetaMask and Passkey
  const handleAuthMethodSelect = (method) => {
    setAuthenticationMethod(method);
    if (method === 'metamask') {
      initializeWallet();
    } else if (method === 'passkey') {
      setStatus('Choose whether to register a new passkey or authenticate with an existing one.');
    }
  };

  // Logout and reset the state
  const logout = () => {
    setAuthenticated(false);
    setProvider(null);
    setWalletAddress('');
    // localStorage.removeItem('passkeyName'); // Clear stored passkey name
    // localStorage.removeItem('credentialId'); // Clear stored credential ID
    // localStorage.removeItem('publicKey'); // Clear stored public key
    setAuthenticationMethod(null);
  };

  // Handle sending transaction
  const handleSend = useCallback(async () => {
    try {
      if (!provider) {
        setStatus('Provider not initialized');
        return;
      }

      setStatus('Sending transaction...');

      // Validate recipient address
      if (!ethers.utils.isAddress(recipient)) {
        throw new Error('Invalid recipient address');
      }

      // Validate the amount
      if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
        throw new Error('Invalid amount');
      }

      const parsedAmount = ethers.utils.parseEther(amount);

      // Get current gas price from the provider
      const baseGasPrice = await provider.getGasPrice();

      // Dynamically adjust maxFeePerGas based on network conditions
      const maxFeePerGas = baseGasPrice.mul(2); // Increase base gas price to prioritize the transaction

      // maxPriorityFeePerGas is the "tip" miners receive to prioritize the transaction
      // It can be a fixed value, usually in the range of 1-2 gwei
      const maxPriorityFeePerGas = ethers.utils.parseUnits('2', 'gwei'); // Set a fixed priority fee

      const tx = {
        to: recipient,
        value: parsedAmount,
        maxFeePerGas, // Set dynamically adjusted fee
        maxPriorityFeePerGas, // Set priority fee
        data: '0x',
      };

      // No need to manually estimate gas, let the bundler handle it
      const txResponse = await provider.getSigner().sendTransaction(tx);

      // Wait for the transaction to be mined
      await provider.waitForTransaction(txResponse.hash, 1, 60000); // Wait for 1 confirmation within 1 min

      setStatus('Transaction sent successfully!');

      // Update balance after transaction
      const address = await provider.getSigner().getAddress();
      updateWalletBalance(provider, address);
    } catch (error) {
      console.error('Error during transaction:', error);
      setStatus(`Error: ${error.message}`);
    }
  }, [provider, recipient, amount]);


  return (
    <div className="container mx-auto p-4 max-w-md">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-center mb-6">Wallet Dashboard</h1>
        <div className="space-y-4">
          {!authenticated ? (
            <div className="space-y-4">
              <button
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                onClick={() => handleAuthMethodSelect('metamask')}
              >
                Use MetaMask
              </button>
              <button
                className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
                onClick={() => handleAuthMethodSelect('passkey')}
              >
                Use Passkey
              </button>
              {authenticationMethod === 'passkey' && (
                <div className="space-y-2">
                  <input
                    type="text"
                    placeholder="Enter passkey name"
                    value={passkeyName}
                    onChange={(e) => setPasskeyName(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                  <button
                    className="w-full bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600 transition duration-200"
                    onClick={registerNewPasskey}
                  >
                    Register New Passkey
                  </button>
                  <button
                    className="w-full bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 transition duration-200"
                    onClick={initializePasskeyProvider}
                  >
                    Use Existing Passkey
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="space-y-4">
              <p className="text-sm">
                <strong>Wallet Address:</strong> {walletAddress || 'Fetching...'}
              </p>
              <p className="text-lg font-semibold">
                Balance: {balance !== '' ? `${balance} INCENTIV` : 'Fetching...'}
              </p>
              <input
                type="text"
                placeholder="Recipient Address"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                className="w-full p-2 border rounded"
              />
              <input
                type="text"
                placeholder="Amount (INCENTIV)"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full p-2 border rounded"
              />
              <button
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 disabled:bg-gray-400"
                onClick={handleSend}
                disabled={!provider}
              >
                {provider ? 'Send' : 'Initializing...'}
              </button>
            </div>
          )}
          {status && (
            <div className="mt-4 p-2 bg-yellow-100 border border-yellow-400 rounded">
              <span className="text-yellow-700">{status}</span>
            </div>
          )}
          {!isMetaMaskAvailable && authenticationMethod === 'metamask' && (
            <p className="mt-4 text-red-500">
              MetaMask is not available. Please install MetaMask to continue.
            </p>
          )}
        </div>
        {authenticated && (
          <button
            className="w-full mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
            onClick={logout}
          >
            Logout
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
