import { ethers } from 'ethers';
import cbor from 'cbor';
import base64url from 'base64url';

// Function to handle passkey registration (creating a new passkey)
export const registerPasskey = async (passkeyName) => {
  const challenge = _generateRandomChallenge();

  const publicKeyCredentialCreationOptions = {
    publicKey: {
      challenge, // Must be ArrayBuffer
      rp: {
        name: 'Incentiv Wallet',
        id: window.location.hostname,
      },
      user: {
        id: new Uint8Array(16), // Generate a unique user ID (use backend or locally)
        name: passkeyName,
        displayName: 'User Display Name',
      },
      pubKeyCredParams: [{ alg: -7, type: 'public-key' }], // P-256 algorithm
      authenticatorSelection: {
        authenticatorAttachment: 'platform', // Prefer platform authenticators
        userVerification: 'required',
      },
      timeout: 60000,
      attestation: 'direct',
    },
  };

  const credential = await navigator.credentials.create(
    publicKeyCredentialCreationOptions
  );

  // Extract the public key from the attestation object
  const publicKey = await _extractPublicKeyFromAttestation(
    credential.response.attestationObject
  );

  // Store the credential ID in localStorage after successful extraction
  const credentialId = base64url.encode(
    new Uint8Array(credential.rawId)
  );
  console.log('credentialId:', credentialId);
  localStorage.setItem('credentialId', credentialId);

  return { credential, publicKey };
};

// Initialize the passkey-based signer for WebAuthn
export const initializePasskeySigner = async () => {
  const credentialId = localStorage.getItem('credentialId');
  if (!credentialId) {
    throw new Error(
      'No stored credential ID found. Please register a passkey first.'
    );
  }

  const allowCredentials = [
    {
      id: base64url.toBuffer(credentialId).buffer,
      type: 'public-key',
    },
  ];

  // Retrieve the stored public key from registration
  const storedPublicKey = localStorage.getItem('publicKey');
  if (!storedPublicKey) {
    throw new Error('Public key not found. Please register a passkey first.');
  }

  const publicKey = JSON.parse(storedPublicKey);

  // Convert base64url to Uint8Array
  const xUint8Array = base64url.toBuffer(publicKey.x);
  const yUint8Array = base64url.toBuffer(publicKey.y);

  // Convert Uint8Array to hex string
  const xHex = ethers.utils.hexlify(xUint8Array).slice(2).padStart(64, '0');
  const yHex = ethers.utils.hexlify(yUint8Array).slice(2).padStart(64, '0');

  // Ensure x and y are 32-byte hex strings
  const publicKeyX = '0x' + xHex;
  const publicKeyY = '0x' + yHex;

  const passkeySigner = {
    async signTransaction(tx) {
      throw new Error('signTransaction not implemented.');
    },
    async signMessage(message) {
      try {
        // Ensure the message is a Uint8Array
        const messageBytes = ethers.utils.isBytes(message)
          ? message
          : ethers.utils.arrayify(message);

        // Prepare messageToVerify as per contract's expectations
        const version = 1;
        const validUntil = Math.floor(Date.now() / 1000) + 24 * 60 * 60;
        const validUntilBytes = ethers.utils.zeroPad(
          ethers.utils.hexlify(validUntil),
          6
        ); // uint48 occupies 6 bytes

        const versionBytes = ethers.utils.zeroPad(
          ethers.utils.hexlify(version),
          1
        ); // uint8 occupies 1 byte

        const messageToVerify = ethers.utils.concat([
          versionBytes,
          validUntilBytes,
          messageBytes,
        ]);

        // Use messageToVerify as the challenge in WebAuthn
        const challenge = messageToVerify;

        // Prepare WebAuthn assertion options
        const publicKeyCredentialRequestOptions = {
          publicKey: {
            challenge,
            rpId: window.location.hostname,
            userVerification: 'required',
            allowCredentials,
            timeout: 60000,
          },
        };

        // Get the assertion from WebAuthn
        const assertion = await navigator.credentials.get(
          publicKeyCredentialRequestOptions
        );

        // Extract necessary components
        const authenticatorData = new Uint8Array(
          assertion.response.authenticatorData
        );
        const clientDataJSON = new Uint8Array(
          assertion.response.clientDataJSON
        );
        const signatureDER = new Uint8Array(assertion.response.signature);

        // Parse r and s from DER-encoded signature
        const { r, s } = _parseDerSignature(signatureDER);

        // Find locations in clientDataJSON
        const clientDataString = new TextDecoder().decode(clientDataJSON);

        // Encode the challenge as base64url for comparison
        const challengeBase64Url = base64url.encode(challenge);
        const challengeProperty = `"challenge":"${challengeBase64Url}"`;
        const challengeLocation = clientDataString.indexOf(challengeProperty);
        const responseType = '"type":"webauthn.get"';
        const responseTypeLocation = clientDataString.indexOf(responseType);

        // Define the Signature struct as a tuple
        const signatureType = {
          components: [
            { name: 'authenticatorData', type: 'bytes' },
            { name: 'clientDataJSON', type: 'string' },
            { name: 'challengeLocation', type: 'uint256' },
            { name: 'responseTypeLocation', type: 'uint256' },
            { name: 'r', type: 'uint256' },
            { name: 's', type: 'uint256' },
          ],
          name: 'Signature',
          type: 'tuple',
        };

        // Prepare the signature data as an object
        const signatureStruct = {
          authenticatorData: authenticatorData,
          clientDataJSON: clientDataString,
          challengeLocation: challengeLocation,
          responseTypeLocation: responseTypeLocation,
          r: ethers.BigNumber.from(r),
          s: ethers.BigNumber.from(s),
        };

        // Encode the Signature struct
        const encodedSignature = ethers.utils.defaultAbiCoder.encode(
          [signatureType],
          [signatureStruct]
        );

        // Construct the final signature with version and validUntil
        const signatureWithVersion = ethers.utils.hexConcat([
          versionBytes,
          validUntilBytes,
          encodedSignature,
        ]);

        return signatureWithVersion;
      } catch (error) {
        console.error('Error during signMessage:', error);
        throw new Error('Passkey signMessage failed.');
      }
    },
    async signUserOp(userOp) {
      throw new Error('signUserOp not implemented.');
    },
    getAddress: async () => {
      return '0x';
    },
    publicKey: {
      x: publicKeyX,
      y: publicKeyY
    },
  };

  return passkeySigner;
};


const _extractPublicKeyFromAttestation = async (attestationObjectBuffer) => {
  // Decode the attestation object using CBOR
  const attestationObject = await cbor.decodeFirst(attestationObjectBuffer);

  // Get the authenticator data from the attestation object
  const authData = new Uint8Array(attestationObject.authData);
  console.log('authData length:', authData.length);

  // Pointer to navigate through authData
  let pointer = 0;

  // 1. rpIdHash (32 bytes)
  const rpIdHash = authData.slice(pointer, (pointer += 32));

  // 2. Flags (1 byte)
  const flagsBuf = authData.slice(pointer, ++pointer);
  const flags = flagsBuf[0];
  const attestedCredentialDataFlag = (flags & 0x40) !== 0;
  console.log('Flags:', flags.toString(2).padStart(8, '0'));
  console.log('Attested Credential Data Flag:', attestedCredentialDataFlag);

  // 3. Sign Count (4 bytes)
  const signCountBuf = authData.slice(pointer, (pointer += 4));
  const signCount = new DataView(signCountBuf.buffer).getUint32(0, false); // Big-endian
  console.log('Sign Count:', signCount);

  if (attestedCredentialDataFlag) {
    // 4. AAGUID (16 bytes)
    const aaguid = authData.slice(pointer, (pointer += 16));

    // 5. Credential ID Length (2 bytes)
    const credIdLenBuf = authData.slice(pointer, (pointer += 2));
    const credIdLen = new DataView(credIdLenBuf.buffer).getUint16(0, false); // Big-endian

    // 6. Credential ID (credIdLen bytes)
    const credentialId = authData.slice(pointer, (pointer += credIdLen));

    // 7. Credential Public Key (variable length)
    const publicKeyBytes = authData.slice(pointer);
    console.log('Public Key Bytes Length:', publicKeyBytes.length);

    // Decode the public key using CBOR
    const publicKeyObject = await cbor.decodeFirst(publicKeyBytes.buffer);
    console.log('Decoded Public Key Object:', publicKeyObject);

    // Extract x and y coordinates using integer keys
    const x = publicKeyObject.get(-2); // 'x' coordinate
    const y = publicKeyObject.get(-3); // 'y' coordinate

    // Check if x and y are present
    if (!x || !y) {
      throw new Error('Public key coordinates are missing.');
    }

    // Store the public key for later use during authentication
    const publicKey = {
      x: base64url.encode(x),
      y: base64url.encode(y)
    };
    localStorage.setItem('publicKey', JSON.stringify(publicKey));

    return { x: new Uint8Array(x), y: new Uint8Array(y) };
  } else {
    throw new Error('Attested credential data flag is not set.');
  }
};

// Helper function to parse DER encoded signature into r and s
function _parseDerSignature(derSignature) {
  let offset = 0;

  if (derSignature[offset++] !== 0x30) {
    throw new Error('Invalid signature format');
  }

  const length = derSignature[offset++];
  if (length + 2 !== derSignature.length) {
    throw new Error('Invalid signature length');
  }

  if (derSignature[offset++] !== 0x02) {
    throw new Error('Invalid r marker');
  }

  let rLength = derSignature[offset++];
  let r = derSignature.slice(offset, offset + rLength);
  offset += rLength;

  if (derSignature[offset++] !== 0x02) {
    throw new Error('Invalid s marker');
  }

  let sLength = derSignature[offset++];
  let s = derSignature.slice(offset, offset + sLength);

  // Adjust r and s if they have leading zeros
  if (r[0] === 0x00) {
    r = r.slice(1);
  }
  if (s[0] === 0x00) {
    s = s.slice(1);
  }

  return { r, s };
}

// Generate a random challenge
function _generateRandomChallenge(length = 32) {
  const challenge = new Uint8Array(length);
  window.crypto.getRandomValues(challenge);
  return challenge;
}
